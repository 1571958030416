<template>
  <div class="categories">
    <Navbar/>  
    <div class="container">
        <CategorieTitle>
            <template #maintitle>
                Школы искусства, моды и дизайна
            </template>
        </CategorieTitle>

        <div class="row school-row" >
            <SchoolCard :url="'/schools/fashion-schools/istituto-marangoni'" :background="'image-maragoni'">
                <template #title>
                    Istituto Marangoni
                </template>
                <template #subtitle>
                    высшее учебное заведение входит в топ-10 лучших школ моды и дизайна в мире. Знаменитые выпускники - Domenico Dolce, Franco Moschino и другие деятели моды и искусства.
                </template>
            </SchoolCard>

            <SchoolCard :url="'/schools/fashion-schools/lisaa'" :background="'image-lisaa'">
                <template #title>
                    LISAA
                </template>
                <template #subtitle>
                    школа основана в 1986 году. Обучение в области графического дизайна, дизайна интерьера, моды, анимации и видеоигр. На английском и французском языках. Кампусы в Париже, Ренн, Страсбурге, Нанте и Бордо.
                </template>
            </SchoolCard>

        </div>

        <div class="row school-row" >

            <SchoolCard :url="'#'" :background="'image-atelier-chardon'">
                <template #title>
                    Atelier Chardon Savard
                </template>
                <template #subtitle>
                    школа моды, основанная в 1988 году. Кампусы в Париже и Нанте.
                </template>
            </SchoolCard>

            <SchoolCard :url="'/schools/fashion-schools/strate'" :background="'image-strate'">
                <template #title>
                    STRATE
                </template>
                <template #subtitle>
                    школа дизайна, которая входит в топ-60 лучших мировых школ в этом направлении и является одной из лучших во Франции.
                </template>
            </SchoolCard>
        </div>


        <div class="row school-row" >
            <SchoolCard :url="'/schools/fashion-schools/iesa'" :background="'image-iesa'">
                <template #title>
                    IESA
                </template>
                <template #subtitle>
                    школа искусства и культуры - одна из самых известных школ управления искусством в Европе.
                </template>
            </SchoolCard>

            <SchoolCard :url="'#'" :background="'image-clcf'">
                <template #title>
                    CLCF
                </template>
                <template #subtitle>
                    основанная в 1963 году, является старейшей независимой школой кино во Франции, которая обучает студентов работе в киноиндустрии в качестве помощников режиссера, редакторов фильмов и сценаристов.
                </template>
            </SchoolCard>
        </div>

        <div class="row school-row" >
            <SchoolCard :url="'/schools/fashion-schools/itm'" :background="'image-itm'">
                <template #title>
                    ITM
                </template>
                <template #subtitle>
                    школа макияжа, готовит визажистов за два-три года для работы в кино, моде, телевидении, рекламе и так далее.
                </template>
            </SchoolCard>

            <SchoolCard :url="'#'" :background="'image-florent'">
                <template #title>
                    Cours Florent
                </template>
                <template #subtitle>
                    французская драматическая школа в Париже, созданная в 1967 году Франсуа Флораном.
                </template>
            </SchoolCard>
        </div>
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/common/Navbar.vue'
import SchoolCard from '@/components/categories/slots/SchoolCard.vue'
import CategorieTitle from '@/components/categories/slots/CategorieTitle.vue'

export default {
  name: 'FashionSchools',
  components: {
    Navbar,
    SchoolCard,
    CategorieTitle
  }
}
</script>
